@import "minima";


body {
    font-family: "Montserrat";
    font-size: larger;
}


.footer-col-1 {
    width: -webkit-calc(35% - (30px / 2));
    width: calc(25% - (30px / 2)); 
}
  
.footer-col-2 {
    width: -webkit-calc(20% - (30px / 2));
    width: calc(40% - (30px / 2)); 
}
  
.footer-col-3 {
    width: -webkit-calc(45% - (30px / 2));
    width: calc(35% - (30px / 2)); 
}

.center-image
{
    margin: 0 auto;
    display: block;
}

.image-caption {
    text-align: center !important;
    font-size: .8rem;
    color: light-grey;
}

